/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */
html,
body {
  height: 100%;
  overflow: hidden;
}

*,
input,
button,
textarea {
  font-family: Inter, Helvetica, Arial, sans-serif;
}

hr {
  background-color: var(--color-neutral-350);
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
}

.fontWeight700 {
  font-weight: 700; // aka "bold"
}

.formLayout {
  & .formElement {
    &.label {
      /** The .formLayout.label selector is designed to format form input
      labels and may be further constrained by the additional classes 'primary'
      (the default) or 'secondary', each engendering different styling. */
      --label-font-size: 14px;
      --label-line-height: 20px;
      --label-font-weight: 600;
      --label-text-color: var(--color-high-emphasis-text);

      &.secondary {
        --label-font-size: 13px;
        --label-line-height: 16px;
        --label-font-weight: 400;
        --label-text-color: var(--color-high-emphasis-text);
      }

      &.disabled {
        --label-text-color: var(--color-disabled-type);
      }

      font-size: var(--label-font-size);
      line-height: 1.0;
      font-weight: var(--label-font-weight);
      color: var(--label-text-color);
      display: inline-flex;
    }

    &.control {
      /** The .formLayout.control selector is designed to wrap two elements: a
      label and a control (specifically, a RadioButton or a CheckBox. */
      --control-flex-direction: row;

      display: inline-flex;
      align-items: center;
      gap: 8px;

      &.vertical {
        --control-flex-direction: column;
      }

      flex-direction: var(--control-flex-direction);
    }

    &.controlSeries {
      /** The .formLayout.controlSeries selector is designed to wrap a series
      of .formLayout.control elements (i.e. labelled radio buttons or labelled
      checkboxes). */
      display: flex;
      flex-wrap: wrap;
      gap: 8px 24px;

      &.vertical {
        flex-direction: column;
      }
    }
  }
}

.flexColumnLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  overflow: hidden;

  &.fullHeight {
    height: 100%;
  }

  & > .flexItem {
    flex: 0 0 auto;

    &.elastic {
      flex: 1 1 auto;
    }

    &.scrollable {
      overflow: auto;
    }
  }
}

.flexBetweenLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .endAligned {
    align-items: flex-end;
  }
}

.tabularNumber {
  font-variant-numeric: tabular-nums;
  white-space: nowrap;

  &.compact {
    letter-spacing: -0.1ch;
  }
}

.tabularCell.wrap {
  white-space: normal;
}

#root {
  height: 100%;
  position: relative;
  z-index: 1;
}

#modals {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  opacity: 1;
  transition: opacity 250ms, z-index 0ms 0ms;
  overflow: hidden;

  &:empty {
    z-index: -1;
    opacity: 0;
    transition: opacity 250ms, z-index 0ms 250ms;
  }
}

#menus {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  &:empty {
    z-index: -1;
  }
}

body:has(.horizontal-dragger-active) {
  &, * {
    cursor: col-resize !important;
  }
}

body:has(.vertical-dragger-active) {
  &, * {
    cursor: row-resize !important;
  }
}