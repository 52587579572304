/* Copyright 2023 Luminary Cloud, Inc. All Rights Reserved. */
.columnSettingsPanel {
  background-color: var(--color-surface-medium1);
  border: 1px solid var(--color-neutral-550);
  border-radius: 4px;
  padding: 8px 0;

  & > .title {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    padding: 5px 8px;
    font-weight: 600;
    color: var(--color-low-emphasis-text);
    white-space: nowrap;
  }

  & > .settings {
    & > .columnSetting {
      --content-opacity: 1;

      &.disabled {
        --content-opacity: 0.5;
      }

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      padding: 4px 8px;

      & > .control {
        flex: 0 0 auto;
      }

      & > .content {
        opacity: var(--content-opacity);
        transition: opacity 500ms;

        & > .label {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          color: var(--color-high-emphasis-text);
        }

        & > .description {
          font-size: 12px;
          line-height: 16px;
          color: var(--color-low-emphasis-text);
        }
      }
    }
  }

  & > .divider {
    background-color: var(--color-neutral-400);
    height: 1px;
    margin-top: 8px;
  }

  & > .actions {
    padding: 8px 8px 0;
    display: flex;
    justify-content: flex-end;
  }
}
