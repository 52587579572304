/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */

.collectionControlBar {

  & > .main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .groupDivider {
    width: 0px;
    height: 30px;
    flex: 0 0 auto;
    border-left: 1px dashed var(--color-low-emphasis-text);
    margin: 0 8px;
  }

  & .controlGroup {
    display: flex;
  }

  & button.barControl {
    --bg-color: transparent;
    --icon-color: var(--color-medium-emphasis-text);

    &:enabled {

      &:hover,
      &.engaged {
        --bg-color: var(--color-surface-light2);
      }

      &:active {
        --bg-color: var(--color-primary-cta);
      }

      &.selected {
        --icon-color: var(--color-primary-cta);
      }
    }

    &:disabled {
      --icon-color: var(--color-disabled-type);
    }

    & {
      background-color: var(--bg-color);
      border: 0;
      color: var(--icon-color);
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 250ms, color 250ms;
    }

    &:enabled {
      cursor: pointer;
    }
  }

  & .actionButtons {
    display: flex;
    gap: 10px;
    padding: 2px;
    padding-left: 6px;
  }
}
